<template>
  <transition name="slide-down" mode="out-in">
    <div class="flex justify-center items-center h-screen">
      <div class="flex-col w-1/4 mb-4">
        <TextInput
          id="input"
          class="mb-4"
          placeholder="Enter Totp"
          v-model="inputText"
          :max-length="6"
          @enter="submit"/>
        <Button
          type="primary"
          class="text-gray-300 mx-auto"
          @onClick="submit"
        >
          Continue
        </Button>
      </div>
    </div>
  </transition>

</template>

<script>
import TextInput from '@/components/TextInput.vue';
import Button from '@/components/Button.vue';
import { mapActions } from 'vuex';

export default {
  name: 'TotpVerify',
  components: { Button, TextInput },
  async mounted() {
    const input = document.getElementById('input');
    input.focus();
    const { data } = await this.totpRecoveryTokens();
    this.recoveryTokens = data.recoveryTokens;
  },
  data() {
    return {
      inputText: '',
      recoveryTokens: [],
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions(['verifyTotp', 'totpRecoveryTokens']),
    submit() {
      try {
        this.verifyTotp({ token: this.inputText, deviceId: '17' });
        setTimeout(async () => {
          await this.$router.push('/report');
        }, 2500);
      } catch (e) {
        console.log(e);
        this.errorMessage = e;
      }
    },
  },
};
</script>

<style scoped></style>
